import React from 'react';

import environmentConfig from 'moduleAlias/environmentConfig';
import { classes } from '@/uiPrimitives/base/classes.helpers';
import { box } from '@/uiPrimitives/layout/box';
import { indentSize } from '@/uiPrimitives/layout/indentSize.definitions';
import { textBlock } from '@/uiPrimitives/typography/text';

/**
 * Will display an error message on non-production environments when
 * special markup cannot be rendered.
 *
 * This helps the editors spot and understand errors on staging before
 * publishing to production.
 *
 * On the production environment no errors are shown.
 */
export const MarkupError = ({ children }: { children: React.ReactNode }) => {
	if (environmentConfig.env === 'productie') {
		return null;
	}

	return (
		<span
			lang="en"
			className={classes(
				box({
					backgroundColor: 'positiveRed',
					paddingInline: indentSize,
					paddingBlock: '3 | h1',
					inlineSize: 'fit-content',
				}),
				textBlock({
					fontStyle: 'normal',
					fontWeight: 'bold',
					whiteSpace: 'pre-line',
				})
			)}
		>
			{children}
		</span>
	);
};
